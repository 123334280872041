const overall = {
    a:{
        time:1,
        title: '개회식 & 축사',
        place: '401'
    },
    b:{
        time:2,
        title: '기조강연 1, 2',
        place: '401'
    },
    b2:{
        time:2,
        title: '발표 세션',
        place: '401'
    },
    c:{
        time:3,
        title: '발표 세션',
        place: '401'
    },
    c2:{
        time:3,
        title: '포스터 세션',
        place: '402'
    },
    d:{
        time:4,
        title: '경품추첨 및 종료',
        place: ''
    },
}



export {overall}